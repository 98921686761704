<template>
  <div class="d-flex">
    <ProfilePicture :user="user" :width="40" :height="40" />

    <div class="ml-2">

      <p>
        You have been assigned as lead owner
      </p>

      <p v-if="item.notification.count === 'multiple'">
        for multiple webshops by
        <b>
          {{ user?.user_name || 'Unknown' }}
        </b>
      </p>

      <p v-else-if="item.notification.count > 1">
        for
        <b>{{ item.notification.name }}</b>
        and
        <b>{{ item.notification.count - 1 }}</b>
        other webshops
      <p>by
        <b>
          {{ user?.user_name || 'Unknown' }}
        </b>
      </p>
      </p>

      <p v-else>
        for
        <b>{{ item.notification.name }}</b>
        by
        <b>
          {{ user?.user_name || 'Unknown' }}
        </b>
      </p>

      <p class="text-subheader2 mt-2">
        {{ timeAgo(item.timestamp) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useCustomerStore } from '~/stores/customer';

const props = defineProps({
  item: Object
})

const customerStore = useCustomerStore()

const user = computed(() => {
  return customerStore.users.find(u => u.user_id === props.item.notification.assigned_by)
})
</script>

<style lang="scss" scoped></style>