<template>
  <div class="d-flex">
    <template v-if="item.notification.change_type === 'won'">
      <NotificationsIcon :clicked="Boolean(item.clicked)" icon="mdi-arrow-top-right" bg="#D1FAE8" bg_new="#D1FAE8" color="#03985F"
        color_new="#03985F" />

      <div class="ml-2">

        <p>
          <b>{{ user.customer }} (you)</b> won <b>{{ item.notification.cur_best }}</b> position from {{
            item.notification.prev_best }}
        </p>
        <p>
          at <b>{{ item.notification.domain }}</b> from <b>{{ item.notification.changed_with }}</b>
        </p>

        <p class="text-subheader2 mt-2">
          {{ timeAgo(item.timestamp) }}
        </p>
      </div>
    </template>

    <template v-if="item.notification.change_type === 'lost'">
      <NotificationsIcon :clicked="Boolean(item.clicked)" icon="mdi-arrow-bottom-right" bg="#FECDCA" bg_new="#FECDCA" color="#D92D20"
        color_new="#D92D20" />

      <div class="ml-2">

        <p>
          <b>{{ user.customer }} (you)</b> lost from <b>{{ item.notification.prev_best }}</b> to <b>{{
            item.notification.cur_best || 'no' }}</b> position
        </p>
        <p>
          at <b>{{ item.notification.domain }}</b> to <b>{{ item.notification.changed_with }}</b>
        </p>

        <p class="text-subheader2 mt-2">
          {{ timeAgo(item.timestamp) }}
        </p>
      </div>
    </template>
  </div>
</template>

<script setup>
import { useUserStore } from '~/stores/user';

const props = defineProps({
  item: Object
})

const user = useUserStore()

</script>

<style lang="scss" scoped></style>