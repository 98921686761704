<template>
  <div class="d-flex">
    <NotificationsIcon :clicked="Boolean(item.clicked)" icon="mdi-playlist-plus" />

    <div class="ml-2">

      <p>
        You have been invited to the list
      </p>
      <p>
        <b>{{ item.notification.name }}</b> by <b>{{ item.notification.invited_by }}</b>
      </p>

      <div class="d-flex mt-1">
        <p style="text-decoration: underline;" class="text-subheader2 mr-2" @click="handleListInviteClick(item)">
          Accept
        </p>
        <p style="text-decoration: underline;" class="text-subheader2 mr-4">Decline</p>
      </div>

      <p class="text-subheader2 mt-2">
        {{ timeAgo(item.timestamp) }}
      </p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  item: Object
})

const router = useRouter()

function handleListInviteClick(item) {
  router.push({
    path: '/accept-list/' + item.notification.token
  })
}
</script>

<style lang="scss" scoped></style>