<template>
  <div class="d-flex">
    <ProfilePicture :user="user" :width="40" :height="40" />

    <div class="ml-2">
      <p>
        <b>
          {{ user?.user_name || 'Unknown' }}
        </b>
        has commented
      <p>on
        <b>
          {{ item.notification.name }}
        </b>
      </p>
      </p>
      <p>
      </p>

      <p class="text-subheader2 mt-2">
        {{ timeAgo(item.timestamp) }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { useCustomerStore } from '~/stores/customer';

const props = defineProps({
  item: Object
})

const customerStore = useCustomerStore()

const user = computed(() => {
  return customerStore.users.find(u => u.user_id === props.item.notification.comment_by)
})
</script>

<style lang="scss" scoped></style>